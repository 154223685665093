body {
    overflow-x: hidden;
}

.homeScreen {
    width: 100%;
}

.footer {
    background-color: #ffd700;
    padding: 2px;
    text-align: right;
    font-size: 14px;
    padding-right: 10px;
  }
  
  .footer p {
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
  }