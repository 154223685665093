.loginScreen::before {
    content: "";
    display: block;
    width: 100%;
    height: 70px;
    background-color: #111;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    opacity: 0; 

    /*Animations*/
    transition: opacity 0.5s ease-in; /* Add transition for opacity */
}

.loginScreen__black-bar::before {
    opacity: 1; 
  }

.loginScreen{ 
    position: relative;
    height: 100%;
    background: url("https://raw.githubusercontent.com/thatanjan/netflix-clone-yt/youtube/media//banner.jpg")
    center no-repeat;
    background-size: cover;
    z-index: 1;
    top: 0;
}

.loginScreen__body > h1 {
    font-size: 3.125rem;
    margin-bottom: 20px;
}

.loginScreen__body > h2 {
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 30px;
}

.loginScreen__body > h3 {
    font-size: 1.3rem;
    font-weight: 400;
}

.loginScreen__body{
    position: absolute;
    top: 20%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    z-index: 1;
    color: #fff;
    left: 0;
    right: 0;

    @media (max-width: 768px) { 
        margin-top: 0.1vh; 
    }
}

.loginScreen__gradient{
    width: 100%;
    z-index: 1;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);
    background-image: linear-gradient(
        to top,
        rgba(0, 0, 0, 0.8) 0,
        rgba(0, 0, 0, 0) 60%,
        rgba(0, 0, 0, 0.8) 100%
    );
}

.loginScreen__button{
    position: fixed;
    right: 20px;
    top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #FFD700;
    font-weight: 600;
    border: none;
    cursor: pointer;
    z-index: 11;

    @media (max-width: 768px) {
        font-size: 0.875rem;
        padding: 0.5rem 1rem;
    } 
}

.loginScreen__logo{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 200px;
    object-fit: contain;
    cursor: pointer;
    z-index: 11;
}

.loginScreen__input > form > input{
    padding: 10px;
    outline-width: 0;
    height: 40px;
    width: 30%;
    border: none;
    max-width: 600px;
    background-color: #111;
    opacity: 0.93;
    font-size: larger;
    font-weight: 550;
    color: #fff;

    @media (max-width :768px) {
        width :90%; 
        max-width :none; 
    }
}

.loginScreen__input{
    margin: 20px;
}

.loginScreen__getStarted{
    padding: 16px 20px;
    font-size: 1rem;
    color: #fff;
    background-color: #FFD700;
    border: none;
    font-size: larger;
    font-weight: 550;
    cursor: pointer;

    @media (max-width :768px) {
      font-size :0.875rem; 
      padding-top :1em; 
      padding-bottom :1em; 
  }
}

.loginScreen__text > h1 {
    font-size: 50px;
    line-height: 1.1;
    margin-bottom: 8px;

    @media (max-width :768px) {
        font-size :40px; 
    }
}

.loginScreen__text > h2 {
    font-size: 26px;
    line-height: 1.1;
    margin-bottom: 8px;

    @media (max-width :768px) {
        font-size :20px; 
    }
}

.loginScreen__back {
    background-color: #111;
    font-size: 16px;
    border-bottom: 8px solid #222;
    padding: 50px 10%;
    color: white;
    overflow: hidden;
    margin-top: 10vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width :768px) {
        margin-top: 5.3vh; 
        flex-direction: column; 
        align-items: center; 
        padding-top: 3em; 
        padding-bottom: 3em; 
    }
}

.loginScreen__textImage {
    max-width: 100%; /* Ensure the image scales down proportionally */
    width: 450px;
    border-radius: 15px;
    overflow: hidden;

    @media (max-width: 768px) {
        width: 80%; /* Adjust the image width on smaller screens */
        margin: 10px auto; /* Center the image on smaller screens */
    }
}


.loginScreen__content {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Adjust as needed */
    margin-bottom: 80px;

    @media (max-width: 768px) {
        flex-direction: column; /* Stack content vertically on smaller screens */
        align-items: center; /* Adjust alignment for smaller screens */
    }
}

.loginScreen__content > div {
    flex: 1; /* Each column takes an equal portion of available space */
    max-width: 33%; /* Set a fixed width for each column */
    text-align: center; /* Center the content inside each column */

    @media (max-width: 768px) {
        max-width: 100%; /* Full width when stacked */
        margin-bottom: 20px; /* Add margin between stacked columns */
    }
}

/* Add separator lines between .loginScreen__content elements */
.loginScreen__text::after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background-color: #FFD700;/* You can adjust the color here */
    margin: 20px auto; /* Adjust the spacing as needed */
  }

.loginScreen__text {
    max-width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }