.nav{
    position: fixed;
    top: 0;
    padding: 20px;
    width: 100%;
    height: 30px;
    z-index: 1;
 

    /*Animations*/
    transition-timing-function: ease-in;
    transition: all 0.5s;
}
 
.nav__contents {
    display: flex;
    justify-content: space-between;
}

.nav__black{
    background-color: #111;  
}

.nav__logo {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 200px;
    object-fit: contain;
    cursor: pointer;
}

.nav__avatar{
    cursor: pointer;
    position: fixed;
    right: 20px;
    width: 30px;
}

.input-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    position: fixed;
    right: 70px;
    top: 15px;
  }
  
  .input {
    border-style: none;
    height: 20px;
    width: 50px;
    padding: 10px;
    outline: none;
    border-radius: 50%;
    transition: .5s ease-in-out;
    background-color: #ffd700;
    box-shadow: 0px 0px 3px #f3f3f3;
    padding-right: 40px;
    color: #fff;
  }
  
  .input::placeholder,
  .input {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
    font-size: 17px;
  }
  
  .input::placeholder {
    color: #8f8f8f;
  }
  
  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    outline: none;
    border-style: none;
    border-radius: 50%;
    pointer-events: painted;
    background-color: transparent;
    transition: .2s linear;
  }
  
  .icon:focus ~ .input,
  .input:focus {
    box-shadow: none;
    width: 250px;
    border-radius: 0px;
    background-color: transparent;
    border-bottom: 3px solid #ffd700;
    transition: all 500ms cubic-bezier(0, 0.110, 0.35, 2);
  }
  
  .socail-media {
    display: flex;
    align-items: right;
    position: absolute;
    top: 12px;
    right: 60px;
    gap: 10px;
    list-style: none;
  }


  .socail-media li a {
    width: 30px;
    height: 30px;
    background-color: #ffd700;
    display: flex;
    overflow: hidden;
    align-items: center;
    align-content: center;
    justify-content: center;
    position: relative;
    z-index: 9;
  }
  
  .socail-media li a svg {
    width: 16px;
    height: 16px;
    -o-object-fit: contain;
    object-fit: contain;
    color: #ffd700;
  }
  
  .socail-media li a::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-clip-path: polygon(0 0, 100% 0, 0 0, 0 100%);
    clip-path: polygon(0 0, 100% 0, 0 0, 0 100%);
    background-color: #fff;
    z-index: -1;
    top: 0;
    left: 0;
    opacity: 0;
  }
  
  .socail-media li a:hover::after {
    animation: sideClip 0.5s linear;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    opacity: 1;
  }
  
  .socail-media li a:hover svg {
    animation: fadeInLeft 0.3s linear both;
    color: #ffd700;
  }
  
  @keyframes sideClip {
    0% {
      clip-path: polygon(0 0, 100% 0, 0 0, 0 100%);
    }
  
    50% {
      clip-path: polygon(0 0, 100% 0, 0 100%, 0 100%);
    }
  
    100% {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
  
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: none;
    }
  }
  
  