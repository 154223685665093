.banner{
    position: relative;
    height: 448px;
    color: white;
    object-fit: contain;  /*maintains the aspect ratio*/
}

.banner__contents{
    margin-left: 30px;
    padding-top: 140px;
    height: 190px;
}

.banner__title{
    font-size: 3rem;
    font-weight: 800;
    padding-bottom: 0;
}

.banner__description{
    width: 45rem;
    line-height: 1.3;
    padding-top: 1rem;
    font-size: 0.8rem;
    max-width: 360px;
    height: 76px;
}

.banner__fadeBottom{
    height: 7.4rem;
    background-image: linear-gradient(
        180deg,
        transparent,
        rgba(37, 37, 37, 0.61),
        #111
    );
}

.banner__button{
    cursor: pointer;
    color: #fff;
    outline: none;
    border: none;
    font-weight: 700;
    border-radius: 0.2vw;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-right: 1rem;
    padding-top: 0.5rem;
    background-color: rgba(51, 51, 51, 0.5);
    padding-bottom: 0.5rem;
    border-radius: 8px;
    margin-bottom: 20px;
}

.banner__button:hover{
    color: #000;
    background-color: #FFD700;
    transition: all 0.2s;
}
/*
.ReactModal__Content--after-open {
  animation: slide-down 0.5s ease-out;
}

.ReactModal__Content--before-close {
  animation: slide-up 0.5s ease-out;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%) translateX(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}*/