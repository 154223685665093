.row {
    color: white;
    margin-left: 16px;
    margin-bottom: -8px; /* Reduced space between rows */
    position: relative; /* Needed for positioning the arrows */
  }
  
  .row__arrows {
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 0;
  }
  
  .row__arrow {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .row__arrow svg {
    fill: rgba(255, 255, 255, 0.8);
  }
  
  .row__arrow:hover svg {
    fill: white;
  }
  
  body {
    overflow-x: hidden;
  }

.row__posters{
    display: flex;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 16px;
}

.row__posters::-webkit-scrollbar {  /*to remove the scroll bar*/
    display: none;
}

.row__poster {
    max-height: 100px;
    object-fit: contain;
    margin-right: 16px;
    width: 100%;
    border-radius: 16px; 
    transition: transform 450ms;
}

.row__poster:hover {
    transform: scale(1.1);
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.row__posterLarge {
    max-height: 230px;
}

.row__posterLarge:hover {
    transform: scale(1.08);
    opacity: 1;
}

.title {
    position: relative; /* Needed for the ::after pseudo-element positioning */
    display: inline-block;
    margin-bottom: 4px; /* Adjust as needed */
  }
  
  .title::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px; /* Adjust the distance of the line from the text */
    width: 100%;
    height: 2px; /* Line height */
    background-color: #ffd700;
  }
  

body.ReactModal__Body--open {
  background-color: rgba(0, 0, 0, 0.5); /* Change the background color to semi-transparent grey */
}